/**
 * @fileoverview gRPC-Web generated client stub for qis.account.v1
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_api_annotations_pb = require('../../../google/api/annotations_pb.js')

var qis_account_v1_account_pb = require('../../../qis/account/v1/account_pb.js')
const proto = {};
proto.qis = {};
proto.qis.account = {};
proto.qis.account.v1 = require('./account_api_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.qis.account.v1.AccountServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.qis.account.v1.AccountServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qis.account.v1.UserAccountRequest,
 *   !proto.qis.account.v1.UserAccountResponse>}
 */
const methodDescriptor_AccountService_CreateUser = new grpc.web.MethodDescriptor(
  '/qis.account.v1.AccountService/CreateUser',
  grpc.web.MethodType.UNARY,
  proto.qis.account.v1.UserAccountRequest,
  proto.qis.account.v1.UserAccountResponse,
  /**
   * @param {!proto.qis.account.v1.UserAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qis.account.v1.UserAccountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.qis.account.v1.UserAccountRequest,
 *   !proto.qis.account.v1.UserAccountResponse>}
 */
const methodInfo_AccountService_CreateUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.qis.account.v1.UserAccountResponse,
  /**
   * @param {!proto.qis.account.v1.UserAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qis.account.v1.UserAccountResponse.deserializeBinary
);


/**
 * @param {!proto.qis.account.v1.UserAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.qis.account.v1.UserAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qis.account.v1.UserAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qis.account.v1.AccountServiceClient.prototype.createUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qis.account.v1.AccountService/CreateUser',
      request,
      metadata || {},
      methodDescriptor_AccountService_CreateUser,
      callback);
};


/**
 * @param {!proto.qis.account.v1.UserAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qis.account.v1.UserAccountResponse>}
 *     Promise that resolves to the response
 */
proto.qis.account.v1.AccountServicePromiseClient.prototype.createUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qis.account.v1.AccountService/CreateUser',
      request,
      metadata || {},
      methodDescriptor_AccountService_CreateUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qis.account.v1.CreateCompanyRequest,
 *   !proto.qis.account.v1.CreateCompanyResponse>}
 */
const methodDescriptor_AccountService_CreateCompany = new grpc.web.MethodDescriptor(
  '/qis.account.v1.AccountService/CreateCompany',
  grpc.web.MethodType.UNARY,
  proto.qis.account.v1.CreateCompanyRequest,
  proto.qis.account.v1.CreateCompanyResponse,
  /**
   * @param {!proto.qis.account.v1.CreateCompanyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qis.account.v1.CreateCompanyResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.qis.account.v1.CreateCompanyRequest,
 *   !proto.qis.account.v1.CreateCompanyResponse>}
 */
const methodInfo_AccountService_CreateCompany = new grpc.web.AbstractClientBase.MethodInfo(
  proto.qis.account.v1.CreateCompanyResponse,
  /**
   * @param {!proto.qis.account.v1.CreateCompanyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qis.account.v1.CreateCompanyResponse.deserializeBinary
);


/**
 * @param {!proto.qis.account.v1.CreateCompanyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.qis.account.v1.CreateCompanyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qis.account.v1.CreateCompanyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qis.account.v1.AccountServiceClient.prototype.createCompany =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qis.account.v1.AccountService/CreateCompany',
      request,
      metadata || {},
      methodDescriptor_AccountService_CreateCompany,
      callback);
};


/**
 * @param {!proto.qis.account.v1.CreateCompanyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qis.account.v1.CreateCompanyResponse>}
 *     Promise that resolves to the response
 */
proto.qis.account.v1.AccountServicePromiseClient.prototype.createCompany =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qis.account.v1.AccountService/CreateCompany',
      request,
      metadata || {},
      methodDescriptor_AccountService_CreateCompany);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qis.account.v1.GetCompanyRequest,
 *   !proto.qis.account.v1.GetCompanyResponse>}
 */
const methodDescriptor_AccountService_GetCompany = new grpc.web.MethodDescriptor(
  '/qis.account.v1.AccountService/GetCompany',
  grpc.web.MethodType.UNARY,
  proto.qis.account.v1.GetCompanyRequest,
  proto.qis.account.v1.GetCompanyResponse,
  /**
   * @param {!proto.qis.account.v1.GetCompanyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qis.account.v1.GetCompanyResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.qis.account.v1.GetCompanyRequest,
 *   !proto.qis.account.v1.GetCompanyResponse>}
 */
const methodInfo_AccountService_GetCompany = new grpc.web.AbstractClientBase.MethodInfo(
  proto.qis.account.v1.GetCompanyResponse,
  /**
   * @param {!proto.qis.account.v1.GetCompanyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qis.account.v1.GetCompanyResponse.deserializeBinary
);


/**
 * @param {!proto.qis.account.v1.GetCompanyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.qis.account.v1.GetCompanyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qis.account.v1.GetCompanyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qis.account.v1.AccountServiceClient.prototype.getCompany =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qis.account.v1.AccountService/GetCompany',
      request,
      metadata || {},
      methodDescriptor_AccountService_GetCompany,
      callback);
};


/**
 * @param {!proto.qis.account.v1.GetCompanyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qis.account.v1.GetCompanyResponse>}
 *     Promise that resolves to the response
 */
proto.qis.account.v1.AccountServicePromiseClient.prototype.getCompany =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qis.account.v1.AccountService/GetCompany',
      request,
      metadata || {},
      methodDescriptor_AccountService_GetCompany);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qis.account.v1.GetCompanyPublicKeyRequest,
 *   !proto.qis.account.v1.GetCompanyResponse>}
 */
const methodDescriptor_AccountService_GetCompanyByPublicKey = new grpc.web.MethodDescriptor(
  '/qis.account.v1.AccountService/GetCompanyByPublicKey',
  grpc.web.MethodType.UNARY,
  proto.qis.account.v1.GetCompanyPublicKeyRequest,
  proto.qis.account.v1.GetCompanyResponse,
  /**
   * @param {!proto.qis.account.v1.GetCompanyPublicKeyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qis.account.v1.GetCompanyResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.qis.account.v1.GetCompanyPublicKeyRequest,
 *   !proto.qis.account.v1.GetCompanyResponse>}
 */
const methodInfo_AccountService_GetCompanyByPublicKey = new grpc.web.AbstractClientBase.MethodInfo(
  proto.qis.account.v1.GetCompanyResponse,
  /**
   * @param {!proto.qis.account.v1.GetCompanyPublicKeyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qis.account.v1.GetCompanyResponse.deserializeBinary
);


/**
 * @param {!proto.qis.account.v1.GetCompanyPublicKeyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.qis.account.v1.GetCompanyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qis.account.v1.GetCompanyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qis.account.v1.AccountServiceClient.prototype.getCompanyByPublicKey =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qis.account.v1.AccountService/GetCompanyByPublicKey',
      request,
      metadata || {},
      methodDescriptor_AccountService_GetCompanyByPublicKey,
      callback);
};


/**
 * @param {!proto.qis.account.v1.GetCompanyPublicKeyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qis.account.v1.GetCompanyResponse>}
 *     Promise that resolves to the response
 */
proto.qis.account.v1.AccountServicePromiseClient.prototype.getCompanyByPublicKey =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qis.account.v1.AccountService/GetCompanyByPublicKey',
      request,
      metadata || {},
      methodDescriptor_AccountService_GetCompanyByPublicKey);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qis.account.v1.AccountLoginRequest,
 *   !proto.qis.account.v1.AccountLoginResponse>}
 */
const methodDescriptor_AccountService_Login = new grpc.web.MethodDescriptor(
  '/qis.account.v1.AccountService/Login',
  grpc.web.MethodType.UNARY,
  proto.qis.account.v1.AccountLoginRequest,
  proto.qis.account.v1.AccountLoginResponse,
  /**
   * @param {!proto.qis.account.v1.AccountLoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qis.account.v1.AccountLoginResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.qis.account.v1.AccountLoginRequest,
 *   !proto.qis.account.v1.AccountLoginResponse>}
 */
const methodInfo_AccountService_Login = new grpc.web.AbstractClientBase.MethodInfo(
  proto.qis.account.v1.AccountLoginResponse,
  /**
   * @param {!proto.qis.account.v1.AccountLoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qis.account.v1.AccountLoginResponse.deserializeBinary
);


/**
 * @param {!proto.qis.account.v1.AccountLoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.qis.account.v1.AccountLoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qis.account.v1.AccountLoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qis.account.v1.AccountServiceClient.prototype.login =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qis.account.v1.AccountService/Login',
      request,
      metadata || {},
      methodDescriptor_AccountService_Login,
      callback);
};


/**
 * @param {!proto.qis.account.v1.AccountLoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qis.account.v1.AccountLoginResponse>}
 *     Promise that resolves to the response
 */
proto.qis.account.v1.AccountServicePromiseClient.prototype.login =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qis.account.v1.AccountService/Login',
      request,
      metadata || {},
      methodDescriptor_AccountService_Login);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qis.account.v1.AccountLoginRequest,
 *   !proto.qis.account.v1.AccountLoginResponse>}
 */
const methodDescriptor_AccountService_GoogleLogin = new grpc.web.MethodDescriptor(
  '/qis.account.v1.AccountService/GoogleLogin',
  grpc.web.MethodType.UNARY,
  proto.qis.account.v1.AccountLoginRequest,
  proto.qis.account.v1.AccountLoginResponse,
  /**
   * @param {!proto.qis.account.v1.AccountLoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qis.account.v1.AccountLoginResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.qis.account.v1.AccountLoginRequest,
 *   !proto.qis.account.v1.AccountLoginResponse>}
 */
const methodInfo_AccountService_GoogleLogin = new grpc.web.AbstractClientBase.MethodInfo(
  proto.qis.account.v1.AccountLoginResponse,
  /**
   * @param {!proto.qis.account.v1.AccountLoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qis.account.v1.AccountLoginResponse.deserializeBinary
);


/**
 * @param {!proto.qis.account.v1.AccountLoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.qis.account.v1.AccountLoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qis.account.v1.AccountLoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qis.account.v1.AccountServiceClient.prototype.googleLogin =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qis.account.v1.AccountService/GoogleLogin',
      request,
      metadata || {},
      methodDescriptor_AccountService_GoogleLogin,
      callback);
};


/**
 * @param {!proto.qis.account.v1.AccountLoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qis.account.v1.AccountLoginResponse>}
 *     Promise that resolves to the response
 */
proto.qis.account.v1.AccountServicePromiseClient.prototype.googleLogin =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qis.account.v1.AccountService/GoogleLogin',
      request,
      metadata || {},
      methodDescriptor_AccountService_GoogleLogin);
};


module.exports = proto.qis.account.v1;

