







































































































































































































































































































































































































































































































































































import Email from '../../services/email';
import { required, minLength, between } from 'vuelidate/lib/validators';
import SyncLoader from 'vue-spinner/src/SyncLoader.vue';

export default {
  name: "home",
  components: {
      SyncLoader
  },
    data() {
        return {
            errorMessages: [],
            contact: {
                name: '',
                email: '',
                cellphone: '',
                message: '',
                token: '',
                button: false,
                submitted: false,
            },
        }
    },
    validations: {
        contact: {
            name: { 
                required,
                minLength: minLength(4),
            },
            email: {
                required,
            },
            cellphone: {
                required,
            },
            message: {
                required
            }
        },
    },
    mounted() {
    },
  methods: {
      contactForm(e) {
        this.contact.submitted = true;
        this.contact.button = true;
        this.errorMessages = [];
        this.$v.$touch();
        if (this.$v.$invalid) {
            this.contact.button = false;
            return;
        }
        this.$recaptcha('hero')
        .then((token) => {
            this.contact.token = token;
            new Email().sendEmail(this.contact).then((result) => {
                this.$swal.fire(
                    'Mensagem recebida com sucesso',
                    'Retornaremos o mais breve possível.',
                    'success'
                )
            })
            .catch(error => {
                this.$swal.fire(
                    'Não recebemos sua mensagem',
                    'Ocorreu um erro, por favor tente novamente mais tarde.',
                    'error'
                )
            })
            .finally(() => {
                this.contact.button = false;
            });
        }).catch(error => {
            this.contact.button = false;
            this.$swal.fire(
                'Não recebemos sua mensagem',
                'Ocorreu um erro, por favor tente novamente mais tarde.',
                'error'
            )
        })
      },
  }
};
